<template>
    <div>
      <!-- <el-dialog title="选择货品分类" :visible.sync="isShow" :close-on-click-modal ="false"> -->
      <div class="chooseDiv">
        <div style="text-align:center;" @click="setRadio('10')">
          <div class="boxDiv">
            <img src="../../../../assets/images/goods/icon_one2.png" alt="">
          </div>
          <el-radio style="margin-top:20px;" v-model="isRadio" label="10">
            一码一货
          </el-radio>
        </div>
        <div style="text-align:center;" @click="setRadio('20')">
          <div class="boxDiv">
            <img src="../../../../assets/images/goods/icon_multiple2.png" alt="">
          </div>
          <el-radio style="margin-top:20px;" v-model="isRadio" label="20">
            一码多货
          </el-radio>
        </div>
      </div>
      <div class="flexDiv">
        <div :class="activeIndex==index?'activeDiv':'noneDiv'" v-for="(item,index) in typeList"
        :key="index" @click="setIndex(index,item.goods_type_id)">
          {{item.goods_type_name}}
        </div>
        <!-- <div class="noneDiv">
          <i class="el-icon-plus" style="font-size:16px;">
          </i>
        </div> -->
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="isShow = false">
          取 消
        </el-button>
        <el-button type="primary" @click="toDetail">
          确 定
        </el-button>
      </span> -->
    <!-- </el-dialog> -->
    </div>
</template>

<script>
import { getListReq } from "@/api/goods/goodsConfig/classify";
export default{
    props:{
        dialogAdd:{
            type:Boolean,
            default:false,
        }
    },
    mounted(){
      this.getGoodsTypeOneList()
      console.log(this.dialogAdd)
      this.isShow=this.dialogAdd
      console.log(this.isShow)
    },
    data(){
        return{
              isShow:false,
            //    dialogAdd:false,//选择货品分类   
               activeIndex:0,
               typeList:[],//选择类型列表
               goods_type_id:'',
               isRadio:'10',
        }
    },
    methods:{

       setRadio(type){
          this.isRadio=type
      },

        //新增页面
        toDetail(){
                // this.isShow=false
                this.$emit('chooseParent')
                this.$router.push({
                path: "/goods/godownEntry/Details/0",
                query: {
                    metaTitle: '新增入库',
                    goods_number_type:this.isRadio,
                    goods_type_id:this.goods_type_id?this.goods_type_id:this.typeList[0].goods_type_id,
                    istype:'add',
                    t:'/goods/godownEntry/Details'
                }
            });
        },
        
         //获取品类下拉数据
        getGoodsTypeOneList() {
          getListReq({ type: [10] }).then((res) => {
            if (res.code === 1) {
              this.typeList = res.data
              // this.changeGoodsType(res.data);
            }
          });
        },

         setIndex(idx,goods_type_id){
            this.activeIndex=idx
            this.goods_type_id=goods_type_id
        },

    }
}
</script>

<style lang='less' scoped>
.chooseDiv{
    display: flex;justify-content:center;
    .boxDiv{border:1px dashed #F1F1F1; margin:0 140px;
        img{width:140px;height: 120px;}
    }
}
.flexDiv{
     display: flex;margin-top:40px;flex-wrap:wrap;padding:0 10px;
    .commonDiv{
      width: 110px;height: 32px;background: #F1F1F1;border-radius: 4px;text-align: center;line-height: 32px;
      margin: 15px 15px 0 0;cursor: pointer;font-size: 12px;border: 1px solid #F1F1F1;
    }
    .noneDiv{
      .commonDiv;
      color: #595959;
    }
    .activeDiv:extend(.noneDiv){
      .commonDiv;
      color: #007BFF;border: 1px solid #007BFF;
    }
}
</style>