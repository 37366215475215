<template>
  <div class="godownEntry_mian">
    <div class="mian_top" >
      <el-form :model="cond" :inline="true">
        <el-form-item label="" class="queryCondition">
          <el-input class="queryCondition_input" placeholder="请输入单据号/供应商/备注" v-model="cond.entry_search_keys"  @keydown.enter.native="onSubmit"></el-input>
        </el-form-item>
        <el-form-item label="入库时间" class="queryCondition">
          <DatePicker ref="DatePickerChild" />
        </el-form-item>
        <el-form-item label="单据状态" class="queryCondition" >
          <el-select v-model="cond.entry_status" placeholder="请选择" style="width:200px;" filterable   multiple collapse-tags>
            <el-option
              v-for="item in options"
              :key="item.entry_status"
              :label="item.status_name"
              :value="item.entry_status">
            </el-option>
          </el-select>
        </el-form-item>
        <div class="mian_top_right">
          <div class="senior_screening_btn" @click="openScreening">高级筛选</div>
          <el-button type="primary" class="senior_screening_query" @click="querylist()">查询</el-button>
          <el-button class="senior_screening_reset" @click="reset()">重置</el-button>
        </div>
      </el-form>
    </div>
    <div class="mian_tab">
      <div class="headerBox">
        <div class="headerBox_title">
          <span>入库单列表</span>
        </div>
        <div class="operate">
          <el-button type="primary" icon="el-icon-plus" size="mini" @click="parentMethod(1)" v-if='AuthpermissionsData[setPermissionsData.create.toLowerCase()]'>新增</el-button>
          <el-button type="danger" icon="el-icon-delete" size="mini"  @click="parentMethod(2)"  v-if='AuthpermissionsData[setPermissionsData.Delete.toLowerCase()]'>删除</el-button>
          <el-button icon="el-icon-plus" size="mini" @click="addOnemore()" v-if='AuthpermissionsData[setPermissionsData.createonemore.toLowerCase()]'>追加</el-button>
          <el-button size="mini"  @click="parentMethod(3)"  v-if='AuthpermissionsData[setPermissionsData.batchUpdateState.toLowerCase()]'>审核</el-button>
          <el-button size="mini"  @click="parentMethod(10)">反审核</el-button>
          <!-- <el-button size="mini"  @click="parentMethod(4)"  v-if='AuthpermissionsData[setPermissionsData.entryPrint.toLowerCase()]'>打印</el-button> -->

          <el-dropdown v-if='AuthpermissionsData[setPermissionsData.entryPrint.toLowerCase()]'>
            <el-button size="mini" style="margin:0 10px;" @click.native="parentMethod(4)">
              打印
              <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
            </el-button>
            <el-dropdown-menu slot="dropdown" >
              <!-- <el-dropdown-item @click.native="parentMethod(4)">打印</el-dropdown-item> -->
              <el-dropdown-item @click.native="parentMethod(8)">打印设置</el-dropdown-item>
              <el-dropdown-item @click.native="parentMethod(9)">打印标签</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>


           <el-dropdown v-if='AuthpermissionsData[setPermissionsData.export.toLowerCase()]' style="margin-right:10px;">
           <el-button icon="el-icon-upload2" size="mini"  @click="parentMethod(5)" >导出</el-button>
            <el-dropdown-menu slot="dropdown" >
              <!-- <el-dropdown-item @click.native="parentMethod(4)">打印</el-dropdown-item> -->
              <el-dropdown-item @click.native="parentMethod(11)">批量货品导出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <el-button icon="el-icon-refresh" size="mini"  @click="parentMethod(6)">刷新</el-button>
        </div>
      </div>
      <listTable class="listTable" ref="child" @getPaginationData="getPaginationData" :condData="cond" :setPermissionsData="setPermissionsData" :AuthpermissionsData="AuthpermissionsData"/>
    </div>
    <div class="mian_footer">
      <div class="mian_page">
        <el-pagination
        :current-page="cond.page"
        background
        layout="total,  prev, pager, next,sizes,jumper"
        :total="total"
        :page-size='this.$store.state.pageSizes[0]'
        :page-sizes="pageSizes"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      ></el-pagination>
      </div>

    </div>
    <!-- 高级筛选 -->
    <seniorScreening ref="search" :searchData="searchData" />
    <!--追加一码多货-->
    <el-dialog title="追加一码多货" :visible.sync="addOnemoreForm" width="75%"  :close-on-click-modal="false">
      <el-row style="margin-bottom: 30px;">
        <el-col :span="24">
          <el-button type="primary" icon="el-icon-plus" size="mini" @click="createData">保存</el-button>
        </el-col>
      </el-row>
      <el-form :model="formQueryOM" ref="onemoreForm" :inline="true"  label-width="120px">
      <el-row>
        <el-col :span="24">
          <el-descriptions title="基本信息"></el-descriptions>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <div class="grid-content">
           
            <el-form-item label="单据日期">
          <el-date-picker
                  value-format="yyyy-MM-dd"
                  :style="'width:300px'"
                  placeholder="请选择日期"
                  v-model="formQueryOM.create_time"
                  type="datetime"
                >
                </el-date-picker>
        </el-form-item>
        <el-form-item label="仓库">
          <el-select v-model="formQueryOM.warehouse_id" placeholder="请选择仓库" filterable clearable >
                <el-option
                  v-for="childItem in cangkuList"
                  :key="childItem.key"
                  :label="childItem.value"
                  :value="childItem.key">
                </el-option>
              </el-select>
        </el-form-item>
        </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content">
            <el-form-item label="品类">
          <el-select v-model="formQueryOM.goodstype_id" placeholder="请选择品类" filterable clearable >
                <el-option
                  v-for="childItem in goodsTypeList"
                  :key="childItem.key"
                  :label="childItem.value"
                  :value="childItem.key">
                </el-option>
              </el-select>
        </el-form-item>
        <el-form-item label="采购人">
          <el-select v-model="formQueryOM.entry_buyer" placeholder="请选择人员" filterable clearable >
                <el-option
                  v-for="childItem in buyer_list"
                  :key="childItem.key"
                  :label="childItem.value"
                  :value="childItem.key">
                </el-option>
              </el-select>
        </el-form-item>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content">
        <el-form-item label="分店">
          <el-select v-model="formQueryOM.shop_id" placeholder="请选择分店" filterable clearable @change="getShopInfoList">
                <el-option
                  v-for="childItem in shopList"
                  :key="childItem.key"
                  :label="childItem.value"
                  :value="childItem.key">
                </el-option>
              </el-select>
        </el-form-item>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="grid-content">
            <el-descriptions title="货品列表">

            </el-descriptions>
            <u-table
       class="listMyTable"
              border
              ref="myTable"
              :data="tableData"
              style="'width: 100%;"
              row-key="index"
              :loading="onemoreLoading"
              @row-click="clickRow"
              :row-class-name="tableRowClassName"
              :cell-class-name="tableCellClassName"
              :data-changes-scroll-top="false"
              highlight-current-row
               resizable="false"
                :row-height="35"
       >
          <u-table-column
                          label="序号"
                          fixed="left" width="80" align="center">
                            <template slot-scope="scope">
                              <div v-if="scope.$index==currentIndex"  class="iconDiv">
                              <i @click="addData" class="el-icon-circle-plus"></i>
                              <i @click="cutData" class="el-icon-remove"></i>
                              </div>
                                <span v-else>{{parseInt(scope.$index)+(1*1)}}</span>
                            </template>
          </u-table-column>
          <u-table-column v-for="(item,index) in rowList"  :key="index+''+item.field"
                   :prop="item.field" align="center"
                  :label="item.field_text" show-overflow-tooltip
                  width="220">
                  <template slot-scope="scope">

<!-- @input="allNumber(formQuery[item.field][0],item.field,0)" -->
<el-input  v-model="tableData[scope.$index][item.field]" 
 :id="'row' + scope.row.index+'line'+index" width="100" height="100"
></el-input>
</template>
          </u-table-column>
       </u-table>
          </div>
        </el-col>
      </el-row>
    </el-form>
    </el-dialog>
  </div>
</template>

<script>
import storage from "good-storage";
import Cookies from "js-cookie";
import DatePicker from "@/components/form/DatePicker.vue";
import listTable from "./components/listTable.vue";
import seniorScreening from "@/components/newCond/seniorScreening.vue";
import {getMyAuth} from "@/api/authority/config";
import {permissionsData} from "@/api/authority/permissions";
import {configuration, getShopEmployeeValue,getShopWarehouseFormValue } from "@/api/components/index.js";
import {
  getEntryStatus,createWareHouseOnemore
} from "@/api/goods/goodsWarehousing/warehousing";
export default {
  data() {
    return {
      onemoreLoading:false,
      options: [],//状态选项list
      //查询配置参数
      scond:{},
      cond: {
          page: 1,
          page_num:  this.$store.state.pageSizes[0],
          entry_status:"",//单据状态
          entry_search_keys:"",//搜索关键词
          create_time:[]//创建时间

      },//高级搜索请求配置参数
      searchData:{
        fun:"entry",
        mode:'search'
      },
      condType:0,//1重置所有查询
      total:0,//表格总数
      pageSizes:this.$store.state.pageSizes,//列表分页快捷配置
      setPermissionsData:{},//权限配置对照数据
      AuthpermissionsData:{},//系统权限数据
      query_entry_number:'',
      formQueryOM:{
        create_time:new Date()
      },
      basisInfoData:{
        fun:"EntryDetails",
        mode:'form'
      },
      addOnemoreForm:false,
      goodsTypeList:{},
      shopList:{},
      cangkuList:{},
      buyer_list:{},
      aloneObj:{},
      tableData:[
        {'goods_number':'','num':'0'},
        {'goods_number':'','num':'0'},
        {'goods_number':'','num':'0'},
        {'goods_number':'','num':'0'},
        {'goods_number':'','num':'0'}
      ],
      rowList:[
        {
          "field":"goods_number",
          "field_text":"货号",
          "type":"string"
        },
        {
          "field":"num",
          "field_text":"数量",
          "type":"number"
        }
      ],
      currentIndex:'-1',//表格点击的横角标
      timer:null
    };
  },

  // 与生命周期同级
  provide() {
    return {
      //子组件调用的名字：对应的方法（当前页面，祖父级元素的方法）
      seniorScreening: this.seniorScreening,
      setDatePicker:this.setDatePicker
    };
  },
  activated(){
    console.log(this.$route)
    if(this.$route.params.key){
      this.cond.entry_search_keys=this.$route.params.key
      this.querylist(false)
      this.cond.entry_search_keys=''
    }else{
      this.query_entry_number=this.$route.params.entry_number
      if(this.query_entry_number){
        this.cond.entry_search_keys=this.query_entry_number
        this.querylist(false)
      }
    }
  },
  components: {
    DatePicker,
    listTable,
    seniorScreening
  },
  created(){
    this.setPermissionsData=permissionsData.godownEntry;//获取入库列表列表权限对照表
    this.getMyAuth();
    this.getBasisInfo()
  },
  methods: {
        //保存
        createData(){
          if(this.timer){
            clearInterval(this.timer)
          }
          this.timer=setTimeout(()=>{
            this.onemoreLoading=true
            let entryinfo=this.formQueryOM
          let form_goodslist=this.tableData
          form_goodslist=JSON.stringify(form_goodslist)=='[{"isEdit":true,"index":0}]'?[]:this.tableData
          let postData={
            entry_info_json:JSON.stringify({
              entryinfo,
              goodsList:form_goodslist
            }),
          }
          console.log("log-json",postData)
          createWareHouseOnemore(postData).then((res)=>{
              console.log("log-res",res)
              if(res.code==1){
                this.$message({
                  message: '入库申请提交成功',
                  type: 'success'
                });
                this.onemoreLoading=false
                this.addOnemoreForm=false
                this.tableData=[]
                this.tableData.push({isEdit:true,...this.aloneObj})
                this.tableData.push({isEdit:true,...this.aloneObj})
                this.formQueryOM.create_time=new Date()
                this.formQueryOM.goodstype_id=''
                this.formQueryOM.shop_id=''
                this.formQueryOM.warehouse_id=''
                this.formQueryOM.entry_buyer=''
                this.parentMethod(6)
              }else{
                this.onemoreLoading=false
              }
          }).catch((err)=>{
                      //this.loading
                      this.onemoreLoading=false
                    })
          },500)
    },
          //添加数据
          addData(){
        if(this.tableData.length>8){
          this.$refs.myTable.scrollBottom()
        }
        this.tableData.push({...this.aloneObj})// this.$forceUpdate()
        
        // this.getFlush()
      },
      cutData(){
        setTimeout(()=>{
          this.tableData.splice(this.currentIndex,1)
        },50)
        if(this.tableData.length==1){
          this.tableData.push({isEdit:true,...this.aloneObj})
        }
      },
      //点击选中复选框
      clickRow(row,column){
          //记录当前点击的角标
          this.currentIndex=row.index
        },
        //设置角标
      tableRowClassName({ row,column, rowIndex,columnIndex}) {
              row.index = rowIndex;
        },

      tableCellClassName({ column,columnIndex}) {
              column.index = columnIndex;
          },

      getShopInfoList(val){
        let post_data={
          hierarchy_id:val
        }
        let p_data=JSON.parse(JSON.stringify(post_data))
        getShopEmployeeValue(p_data).then((res) => {
          if(res.code==1){
            this.buyer_list=res.data
          }
        })

        getShopWarehouseFormValue(p_data).then((res) => {
          if(res.code==1){
            this.cangkuList=res.data
          }
        })
        
      },
    getBasisInfo(type){
          let oneMoreData=JSON.parse(JSON.stringify(this.basisInfoData))
          configuration(oneMoreData).then((res) => {
          if(res.code==1){
            res.data.forEach((item,idx) => {
              if(item['field']=='goods_type_id'){
                this.goodsTypeList=item['option']
              }
              if(item['field']=='hierarchy_id'){
                this.shopList=item['option']
              }
            });
            console.log("log-plei",this.goodsTypeList)
            console.log("log-dianpu",this.shopList)
          }
        })

        //默认
        this.rowList.forEach((item,idx)=>{
          console.log("log-item",item.field)
          this.aloneObj[item.field]=item.type==='string'?'':0
        })
      },
    addOnemore(){
      console.log("log-onemore",new Date())
      this.addOnemoreForm=true
    },
    //获取审核状态
    getEntryStatus(){
      getEntryStatus({}).then((res) => {
        if(res.code==1){
          this.options=res.data;

        }
      })

    },//设置时间值
    setDatePicker(data){
      this.cond.create_time=data;
    },
    //获取系统权限
    getMyAuth(){
      getMyAuth({}).then((res) => {
        this.AuthpermissionsData=res.data;
        this.getEntryStatus();
      })
    },
    //打开高级筛选
    openScreening(){

      let oData
      if(this.condType==1){
        oData=JSON.parse(JSON.stringify(this.cond))//处理数据污染不转化会把原始数据污染
        this.condType=0;
      }else{
        oData=JSON.parse(JSON.stringify(this.scond))//处理数据污染不转化会把原始数据污染
      }

      oData.entry_status=this.cond.entry_status;
      oData.entry_search_keys=this.cond.entry_search_keys;
      oData.create_time=this.cond.create_time;
      oData.page=this.cond.page;
      oData.page_num=this.cond.page_num;
      this.$refs.search.setScreening(true,oData)
    },//搜索，以及高级搜索传参
    seniorScreening(type,cond){

      if(type==1){//高级搜索

        cond.page=1;
        cond.page_num=cond.page_num;
        this.cond.entry_status=cond.entry_status;
        this.cond.create_time=cond.create_time;
        this.scond=cond;
        this.$refs.DatePickerChild.setData(this.cond.create_time);
        // return;
        this.$refs.child.childMethod(7,this.scond);
      }
    },//当前页面条件查询
    querylist(falg){
      this.$refs.child.childMethod(7,JSON.parse(JSON.stringify(this.cond)),falg);
    },//重置当前页面条件查询
    reset(){
      this.cond.entry_status="";
      this.cond.entry_search_keys="";
      this.cond.create_time=[];
      this.$refs.DatePickerChild.setData('');
      this.condType=1;
      this.$refs.child.childMethod(7,JSON.parse(JSON.stringify(this.cond)));
    },
    //入库单列表操作按钮父组件调用子组件方法
    parentMethod(type){
      this.$refs.child.childMethod(type);
    },//父组件接收子组件总条数
    getPaginationData(total){
      this.total=total;
    },//前后分页操作
    handleCurrentChange(page){
      this.$refs.child.childHandleCurrentChange(page,this.cond.page_num);
    },//快捷页码跳页
    handleSizeChange(limit){
        this.$refs.child.childHandleCurrentChange(1,limit);
    }
  },
};
</script>
<style lang="less" >
  .godownEntry_mian{
    .el-input .el-input__inner {
      font-size: 14px;
      height: 32px;
      line-height: 32px;
    }
    .mian_footer{
        position: sticky;
        left: 0px;
        bottom: 0px;
        background: #fff;
        width: calc(100% );
        padding-bottom: 10px;
       .mian_page{
        padding: 15px 0;
        background: #fff;
        margin-right: 20px;
        display: flex;
        justify-content: flex-end;

        .el-pagination.is-background .el-pager li {
            border-radius: 2px;
            font-weight: normal;
            background-color:#FFFFFF;
            border: 1px solid #D9D9D9;
            width: 32px;
            height: 32px;
            line-height: 32px;
        }
        .el-pagination.is-background .el-pager li:not(.disabled).active {
            background-color: #409EFF;
            color: #FFF;
        }
        .el-pagination.is-background .btn-prev {
            border-radius: 2px;
            border: 1px solid #ddd;
            background: white;
            height: 32px;
            line-height: 32px;
        }
        .el-pagination.is-background .btn-next {
            border-radius: 2px;
            border: 1px solid #ddd;
            background: white;
            height: 32px;
            line-height: 32px;
        }

      }
    }


  }
  .iconDiv{
.el-icon-circle-plus{
  color:#0075FF;font-size:16px;margin:0 10px;cursor: pointer;
}
.el-icon-remove{
  color:#FA5050;font-size:16px;cursor: pointer;
}
}
</style>
<style lang="less" scoped>

.godownEntry_mian{
  margin: 5px 10px;
  height: calc(100vh - 230px);
  padding-bottom: 80px;
  position: relative;
}
  .mian_top{
    background: #fff;
    padding: 9px 20px;
    margin-bottom: 12px;
    .mian_top_right{
      float: right;
      display: flex;
      padding-top:3px;
      .senior_screening_btn{
        width: 80px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #0075FF;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #0075FF;
        cursor: pointer;
      }
      .senior_screening_query{
        width: 68px;
        height: 32px;
        line-height: 32px;
        background: #0075FF;
        text-align: center;
        color: #fff;
        border-radius: 4px 4px 4px 4px;
        margin-left: 20px;
        margin-right: 8px;
        cursor: pointer;
        padding: 0;
      }
      .senior_screening_reset{
        width: 68px;
        height: 32px;
        line-height: 32px;
        background: rgba(255,255,255,0.1);
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #D8D8D8;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #595959;
        text-align: center;
        cursor: pointer;
        padding: 0;
      }
    }
  }
  .queryCondition{
    display: inline-block;
    margin: 0;
    margin-right: 20px;
    font-size: 12px;
    .queryCondition_input{
      width: 200px;
    }
  }
  .mian_tab{
    height:calc(100% - 24px);
    background: #fff;
    padding: 12px 20px;
    min-height: 500px;
    .listTable{
      height:calc(100% - 1540px);
    }
    .headerBox_title{
      font-size: 16px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      color: #595959;

    }
    .headerBox{
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
      align-items: center;
    }
  }
</style>
